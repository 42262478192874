<template>
  <div class="news-more-conteiner">
    <van-sticky>
      <head-bar type="primary" title="动态" @onClickLeft="onClickLeft" />
    </van-sticky>
    <div class="head-banner">
      <Banner :active="false" />
    </div>
    <div class="tab-bar">
      <van-tabs v-model="active">
        <van-tab title="岳麓峰会"></van-tab>
        <van-tab title="湖南湘江新区"></van-tab>
      </van-tabs>
    </div>
    <div class="news-list-container">
      <transition name="meeting">
        <div class="meeting-news-list" v-show="active === 0 && !loading">
          <div
            class="news-item"
            v-for="item in newsList"
            :key="item.id"
            @click="showNewsPage(item)"
            v-show="item.display === 1"
          >
            <div class="news-left">
              <span class="news-title">
                <span>{{ item.title }}</span>
              </span>
             <div class="news-bottom">
                <span class="news-time">{{ getRealTime(item) }}</span>
                <!-- <span class="news-time">{{ item.createTime.substr(0, 10) }}</span> -->
                <!-- <span class="click-num">
                  <img :src="require('@/assets/images/ylfh/icon_eye.png')" alt="">
                  <span>{{item.clickNum}}</span>
                </span> -->
              </div>
            </div>
            <div class="news-right">
              <img :src="item.iconUrl+'?imageView2/2/w/375'" alt="" />
            </div>
          </div>
          <van-empty description="暂无数据" v-if="!hasMeeting && !loading"></van-empty>
        </div>
      </transition>
      <transition name="government">
        <div class="government-news-list" v-show="active === 1 && !loading">
          <div
            class="news-item"
            v-for="item in newsList"
            :key="item.id"
            @click="showNewsPage(item)"
            v-show="item.display === 2"
          >
            <div class="news-left">
              <span class="news-title">
                <span>{{ item.title }}</span>
              </span>
              <div class="news-bottom">
                <!-- <span class="news-time">{{ item.createTime.substr(0, 10) }}</span> -->
                <span class="news-time">{{ getRealTime(item) }}</span>
                <!-- <span class="click-num">
                  <img :src="require('@/assets/images/ylfh/icon_eye.png')" alt="">
                  <span>{{item.clickNum}}</span>
                </span> -->
              </div>
            </div>
            <div class="news-right">
              <img :src="item.iconUrl+'?imageView2/2/w/375'" alt="" />
            </div>
          </div>
          <van-empty description="暂无数据" v-if="!hasGovernment && !loading"></van-empty>
        </div>
      </transition>
      <div class="loading" v-show="loading">
        <van-loading></van-loading>
      </div>
    </div>
    <float-menu />
  </div>
</template>

<script>
import Banner from "@/components/banner/index"
import floatMenu from "@/components/floatMenu/index"
import headBar from '@/components/headBar/headBar'
import { Dialog } from "vant"
export default {
  name: "newsMore",
  components: { Banner, floatMenu, headBar },
  data() {
    return {
      active: 0,
      loading: false,
      newsList: []
    }
  },
  computed: {
    hasMeeting() {
      const index = this.newsList.findIndex(item => {
        return item.display === 1
      })
      return index !== -1
    },
    hasGovernment() {
      const index = this.newsList.findIndex(item => {
        return item.display === 2
      })
      return index !== -1
    }
  },
  activated() {
    this.getNewsList()
  },
  methods: {
    getRealTime(item) {
      if(item.subtitle.indexOf('20') !== -1 && item.subtitle.indexOf('-') !== -1) {
        return item.subtitle
      }else {
        return item.createTime.substr(0, 10)
      }
    },
    async getNewsList() {
      this.loading = true
      // let result = await this.$http.get( this.$baseURL.index.getNewsList() + "?miceLink=" + this.$store.state.miceInfo.miceLink + "&isDisplay=2", 1 )
      const result = await this.$http.post(this.$baseURL.index.getNewsPageList(), {
        miceLink: this.$store.state.miceInfo.miceLink,
        pageIndex: 1,
        pageSize: 100
      })
      if (result.total && result.records.length > 0) {
        this.newsList = []
        this.loading = false
        if (result.records.length > 0) {
          result.records.forEach((item) => {
            if (item.display !== 0) {
              this.newsList.push(item)
            }
          })
        }
      } else {
        this.loading = false
        Dialog.alert({
          title: "提示",
          message: result.message
        }).then(() => {
          // on close
        })
      }
    },
    showNewsPage(item) {
      if (item.linkType === 0) {
        window.location.href= item.linkUrl
      } else {
        this.$router.push('/selfpage/' + item.linkUrl + '/' + this.$store.state.miceInfo.miceLink)
      }
      this.addClickNum(item.id)
    },
    onClickLeft() {
      this.$router.push('/index/' + this.$store.state.miceInfo.miceLink || this.$route.params.miceLink)
    },
    // 新增点击量
    addClickNum(id) {
      this.$http.post(this.$baseURL.index.clickNum(), {
        id,
        tableType: 2
      }, 1).then((res) => {
        console.log(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.news-more-conteiner {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(242, 248, 252);
  .news-list-container {
    padding: 15px;
    position: relative;
    .news-item {
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      width: 100%;
      height: 110px;
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(183, 180, 188, 0.14);
      border-radius: 10px;
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
      .news-left {
        padding: 0px 16px 5px 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .news-title {
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 13px;
          line-height: 21px;
          color: #333333;
          span {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
          .news-bottom {
            display: flex;
            justify-content: space-between;
            .news-time {
              font-size: 12px;
              color: #666666;
            }
            .click-num {
              font-size: 11px;
              color: #696969;
              img {
                width: 13px;
                margin-right: 3px;
              }
            }
          }
      }
      .news-right {
        width: 120px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
        }
      }
    }
  }
  .loading{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/deep/.van-tabs__line {
  background-color: rgb(61, 150, 236);
}
/deep/.van-tab--active {
  color: rgb(61, 150, 236);
}
.meeting-enter-active,
.meeting-leave-active,
.government-enter-active,
.government-leave-active{
  position: absolute;
  width: calc(100% - 30px);
  transition: all .4s ease
}
.government-enter,
.government-leave-to{
  opacity: .1;
  transform: translateX(100%);
}
.meeting-enter,
.meeting-leave-to{
  opacity: 0;
  transform: translateX(-100%);
}
</style>